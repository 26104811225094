var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _vm._m(0),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-6 col-md-6 col-sm-12" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: _vm.errors.has("title_vi") ? "border-danger" : "",
                  attrs: {
                    placeholder: "Tiếng việt",
                    "data-vv-name": "title_vi",
                    "data-vv-as": "Tiêu đề",
                  },
                  model: {
                    value: _vm.form.title_vi,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "title_vi", $$v)
                    },
                    expression: "form.title_vi",
                  },
                }),
                _vm.errors.has("title_vi")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("title_vi"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-6 col-md-6 col-sm-12" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: _vm.errors.has("title_en") ? "border-danger" : "",
                  attrs: {
                    placeholder: "English",
                    "data-vv-name": "title_en",
                    "data-vv-as": "Tiêu đề",
                  },
                  model: {
                    value: _vm.form.title_en,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "title_en", $$v)
                    },
                    expression: "form.title_en",
                  },
                }),
                _vm.errors.has("title_en")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("title_en"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: _vm.errors.has("slug") ? "border-danger" : "",
                  attrs: {
                    placeholder: "Link",
                    "data-vv-name": "slug",
                    "data-vv-as": "Link",
                  },
                  model: {
                    value: _vm.form.slug,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "slug", $$v)
                    },
                    expression: "form.slug",
                  },
                }),
                _vm.errors.has("slug")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("slug"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    placeholder: "Mô tả tiếng Việt",
                  },
                  model: {
                    value: _vm.form.description_vi,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "description_vi", $$v)
                    },
                    expression: "form.description_vi",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    placeholder: "Mô tả tiếng Anh",
                  },
                  model: {
                    value: _vm.form.description_en,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "description_en", $$v)
                    },
                    expression: "form.description_en",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.display_homepage,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "display_homepage", $$v)
                    },
                    expression: "form.display_homepage",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Lưu lại")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Tiêu đề")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Link")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("b", [_vm._v("Mô tả Tiếng Việt")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("b", [_vm._v("Mô tả Tiếng Anh")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("b", [_vm._v("Hiển thị ở trang chủ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }